import { Form, Button, Container, Row, Col, Table } from 'react-bootstrap';
import style from './Pages.module.css';
import { useState } from "react";
import { useEffect } from "react";
import { getDatabase, onValue, ref, remove, set } from 'firebase/database';
import Navigation from '../components/Navigation/Navigation';
import _ from 'underscore';

const AdminPage = (props) => {

    const db = getDatabase();

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [mailSent, setMailSent] = useState(false);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const messagesRef = ref(db, "opentech-partners/messages");
        onValue(messagesRef, (snapshot) => {
            const messagesObject = snapshot.val();
            const listOfMessages = Object.keys(messagesObject).map(key => ({
                ...messagesObject[key],
                uid: key
            }));
            setMessages(listOfMessages);
            console.log('Messages... ', listOfMessages.length);
        });
    }, [db]);

    useEffect(() => {
        console.log('messages: ', messages.length);
    }, [messages]);

    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onTelChange = (e) => {
        setTel(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const uuid = crypto.randomUUID();

        const newMessageRef = ref(db, `opentech-partners/messages/${uuid}`);
        const form = {
            name: name,
            tel: tel,
            email: email,
            message: message,
            mailSent: false,
            error: null
        }

        set(newMessageRef, form)
            .then(() => {
                setMailSent(true);
            })
            .catch(error => console.log(error));

        console.log('Form: ', form);

    }

    const deleteMessage = (e) => {
        e.preventDefault();
        remove(ref(db, `opentech-partners/messages/${e.target.value}`));
    }

    return (
        <div>
            <Row>
                <Navigation />
            </Row>

            <Row>

                <Col sm={{ span: 10, offset: 1 }}>

                    <Row style={{ marginBottom: '50px' }}>
                        <Col>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Received</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Tel</th>
                                        <th>Message</th>
                                        <th>Controls</th>
                                    </tr>
                                </thead>
                                {messages.length > 0 ?
                                    <tbody>
                                        {messages.map((message, index) => (
                                            <tr key={index}>
                                                {message.message != "Test message. DO NOT DELETE" ?
                                                    <>
                                                    <td>{message.received}</td>
                                                        <td>{message.name}</td>
                                                        <td>{message.email}</td>
                                                        <td>{message.tel}</td>
                                                        <td>{message.message}</td>
                                                        <td>
                                                            <Button variant="danger" value={message.uid} onClick={(e) => deleteMessage(e)}>Delete</Button>
                                                        </td>
                                                    </>
                                                    :
                                                    <></>
                                                }
                                            </tr>
                                        ))}
                                    </tbody>
                                    :
                                    ''
                                }
                            </Table>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default AdminPage;