import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import HomePage from './pages/HomePage';
import CandidatesPage from './pages/CandidatesPage';
import AboutPage from './pages/AboutPage';
import ClientsPage from './pages/ClientsPage';
import ContactPage from './pages/ContactPage';
import SignInPage from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import AdminPage from './pages/AdminPage';
import SectorsPage from './pages/SectorsPage';

import './index.css';
import EcologiPage from './pages/EcologiPage';

import Firebase, { FirebaseContext } from './firebase';

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/about',
    element: <AboutPage />
  },
  {
    path: '/candidates',
    element: <CandidatesPage />
  },
  {
    path: '/clients',
    element: <ClientsPage />
  },
  {
    path: '/contact',
    element: <ContactPage />
  },
  {
    path: '/sign-in',
    element: <SignInPage />
  },
  {
    path: '/sign-up',
    element: <SignUpPage />
  },
  {
    path: '/admin',
    element: <AdminPage />
  },
  {
    path: '/ecologi',
    element: <EcologiPage />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirebaseContext.Provider value={new Firebase()}>
      <RouterProvider router={router} />
    </FirebaseContext.Provider>
  </React.StrictMode>
);
