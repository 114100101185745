import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import style from './Pages.module.css';
import { useState } from "react";
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import NavigationWhite from '../components/Navigation/NavigationWhite';
import { useNavigate, redirect } from 'react-router-dom';

const SignInPage = (props) => {

    const navigate = useNavigate();

    const auth = getAuth();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const onHandleSubmit = () => {
        console.log(email);
        console.log(password);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            console.log('user: ', user);
            navigate('/admin')

        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(`${errorCode} error. Error message: ${errorMessage}`);
        });
    }  

    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            console.log(uid);
        } else {
            console.log('No user');
        }
    })

    return (
        <div>
            <Row>
                <NavigationWhite />
            </Row>

            <Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ marginBottom: '50px' }}>
                        <Col>
                            <div className={style.textContainer}>
                                <div className={style.textContainerInner}>
                                    <div className={style.contactFormContainer}>
                                        <Container fluid>
                                            <Row>
                                                <Col lg={{ offset: 2, span: 8 }}>
                                                    <Form>
                                                        
                                                        <Form.Group className={`mb-3 ${style.contactFormInput}`}>
                                                            <Form.Label className={style.contactFormLabel}>Email address</Form.Label>
                                                            <Form.Control type="email" value={email} onChange={e => onEmailChange(e)} />
                                                        </Form.Group>

                                                        <Form.Group className={`mb-3 ${style.contactFormInput}`}>
                                                            <Form.Label className={style.contactFormLabel}>Password</Form.Label>
                                                            <Form.Control type="password" value={password} onChange={e => onPasswordChange(e)} />
                                                        </Form.Group>

                                                        <Button type="submit" onClick={onHandleSubmit}>
                                                            Sign in
                                                        </Button>

                                                    </Form>
                                                </Col>
                                            </Row>

                                        </Container>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default SignInPage;