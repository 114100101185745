import { Link } from 'react-router-dom';
import { Navbar, Container, Nav } from 'react-bootstrap';
import logo from '../../img/logo/green-gradient/Opentech_logo_long_gradient.png';

const Navigation = () => {

    const brandStyle = {
        height: '70px'
    }

    const navItemStyle = {
        color: '#0b8752', 
        marginRight: '30px',
        textDecoration: 'none'
    }

    return (
        <Navbar expand="lg">
            <Container fluid>
                <Navbar.Brand href="#home" style={{ color: '#fff' }}><img src={logo} style={brandStyle} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="m-auto">
                                <span>
                                    <Link to="/" style={navItemStyle}>Home</Link>
                                </span>
                                <span>
                                    <Link to="/about" style={navItemStyle}>About OTP</Link>
                                </span>
                                {/* <Nav.Link>
                                    <Link to="/candidates" style={navItemStyle}>Open positions</Link>
                                </Nav.Link> */}
                                <span>
                                    <Link to="/clients" style={navItemStyle}>Clients</Link>
                                </span>
                                <span>
                                    <Link to="/contact" style={navItemStyle}>Contact</Link>
                                </span>
                                <span>
                                    <Link to="/ecologi" style={navItemStyle}>Ecologi</Link>
                                </span>
                                <span style={{ display: 'none' }}>
                                    <Link to="/login" style={navItemStyle}>Log in</Link>
                                </span>
                                <span style={{ display: 'none' }}>
                                    <Link to="/admin" style={navItemStyle}>Admin</Link>
                                </span>
                            </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar >
    );
}

export default Navigation;