import { Container, Row, Col, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import DeansgateSquare from '../img/deansgate-square-optimised.jpg';
import Angles from '../img/angles-optimised.jpg';
import CardOne from '../img/card1.jpg';
import CardTwo from '../img/card2.jpg';
import CardThree from '../img/card3.jpg';
import Footer from '../components/Footer/Footer';
import { Link } from 'react-router-dom';

const AboutPage = () => {

    const homepageBackground = {
        backgroundImage: `url(${Angles})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh'
    }

    const card = {
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)',
        color: '#fff',
        marginBottom: '25px'
    }

    const cardText = {
        padding: '30px'
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px'
    }

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ backgroundColor: 'rgba(0,0,0,0.3' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>About OTP</h1>
                                <h2 style={{ color: '#fff', marginBottom: '50px', fontSize: '2em' }}>Trusted partnerships. Opening doors in the technology industry</h2>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                    <Col sm={{span: 6, offset: 3 }}>
                        <div>
                            <p>Our mission is to deliver outstanding customer service, ensuring that OpenTech work as an extension of our partners, understanding their issues and aiding in their goals. Relationship building within the recruitment industry is paramount, and this is something not overlooked at OpenTech, meaning our commitment to the growth and development of the organisations we work with will always be at the forefront of our operations.</p>
                        </div>
                    </Col>
                </Row>

                <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={{ span: 5, offset: 1 }}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={DeansgateSquare} alt="Buildings at Deansgate Square, Manchester" style={{ width: '100%' }} />
                        </div>

                    </Col>
                    <Col sm={5}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', alignText: 'center', padding: '50px' }}>
                            <p>OpenTech Partners offer recruitment solutions across various sectors within the IT, Digital and Technology industry. Our qualified and market-leading staff identify the needs and wants of an organisation, and recognise the most suitable talent for such requirements. Our team of experts are able to support our partners of various sizes including start-ups and SME’s through to global technology firms with thousands of employees. With this, wherever you are in your journey, OpenTech Partners will be able to add value in the next steps.</p>
                        </div>

                    </Col>
                </Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={4}>
                            <div style={card}>
                                <img src={CardOne} alt="A meeting room" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>View roles</h3>
                                    <p>Coming soon - Search for your next role</p>
                                    <Button style={buttonStyle} disabled>
                                        <Link to="/candidates" style={{ color: '#fff', textDecoration: 'none' }}>View roles</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardTwo} alt="Two women writing on a white board" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Join us</h3>
                                    <p>Join us today and start searching for your new role</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>Join us</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardThree} alt="An office block" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Clients</h3>
                                    <p>Contact us about your IT recruitment needs</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>How we work</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Col>

               <Footer />

            </Container>
        </div>
    );
}

export default AboutPage;