import { Container, Row, Col, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import Trees from '../img/trees-optimised.jpg';
import EcologiProject from '../img/ecologi-project.jpg';
import CardOne from '../img/card1.jpg';
import CardTwo from '../img/card2.jpg';
import CardThree from '../img/card3.jpg';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

const EcologiPage = () => {

    const homepageBackground = {
        backgroundImage: `url(${Trees})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '60vh'
    }

    const card = {
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)',
        color: '#fff'
    }

    const cardText = {
        padding: '30px'
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px'
    }

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ backgroundColor: 'rgba(0,0,0,0.3' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>Ecologi</h1>
                                <h2 style={{ color: '#fff', marginBottom: '50px', fontSize: '2em' }}>Our green approach</h2>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <div>
                            <h5 style={{ marginBottom: '30px' }}>#LetsGrowTogether #WePlaceWePlant</h5>
                            <a href="https://ecologi.com/opentech">
                                <Button style={buttonStyle}>Check out how our forest is getting on</Button>
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={{ span: 5, offset: 1 }}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={EcologiProject} alt="High rise buildings" style={{ height: '100%' }} />
                        </div>

                    </Col>
                    <Col sm={5}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', alignText: 'center', padding: '50px' }}>
                            <p>OpenTech is passionate about promoting a sustainable business plan. With this, for every candidate that secures a new position through OpenTech, we will plant two extra trees in OpenTech's forest on top of the 10 per month OpenTech plants per employee. That's one for the candidate, and one for the client. Our promise? Have a full OpenTech forest in our first year! So, as we place, we plant... let's grow together.</p>
                        </div>

                    </Col>
                </Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={4}>
                            <div style={card}>
                                <img src={CardOne} alt="A meeting room" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>View roles</h3>
                                    <p>Coming soon - Search for your next role</p>
                                    <Button style={buttonStyle} disabled>
                                        <Link to="/candidates" style={{ color: '#fff', textDecoration: 'none' }}>View roles</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardTwo} alt="Two women writing on a white board" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Join us</h3>
                                    <p>Join us today and start searching for your new role</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>Join us</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardThree} alt="An office block" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Clients</h3>
                                    <p>Contact us about your IT recruitment needs</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>How we work</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Col>

                <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={{ span: 5, offset: 1 }}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', alignText: 'center', padding: '50px' }}>
                            <p>We have partnered with Ecologi to help reverse climate change. We reinvest a portion of our profits back into the environment and help fund carbon offset projects and tree planting around the world.</p>
                        </div>

                    </Col>
                    <Col sm={{ span: 5 }}>
                        <div style={{ height: '400px', width: '100%', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={EcologiProject} alt="The Ecologi project" style={{ height: '100%' }} />
                        </div>

                    </Col>
                </Row>

                <Footer />

            </Container>
        </div>

    );
}

export default EcologiPage;