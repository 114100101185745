import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import DeansgateSquare from '../img/deansgate-square-optimised.jpg';
import ContactImage from '../img/contact.jpg';
import Footer from '../components/Footer/Footer';
import { useState, useEffect } from 'react';
import { getDatabase, onValue, ref, set } from 'firebase/database';
import dayjs from 'dayjs';

const ContactPage = () => {

    const db = getDatabase();

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [mailSent, setMailSent] = useState(false);
    const [messages, setMessages] = useState([]);

    const homepageBackground = {
        backgroundImage: `url(${ContactImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top',
        height: '60vh'
    }

    const buttonStyleReverse = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px',
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)'
    }

    

    useEffect(() => {
        const messagesRef = ref(db, "opentech-partners/messages");
        onValue(messagesRef, (snapshot) => {
            const messagesObject = snapshot.val();
            const listOfMessages = Object.keys(messagesObject).map(key => ({
                ...messagesObject[key],
                uid: key
            }));
            setMessages(listOfMessages);
            console.log('Messages... ', listOfMessages.length);
        });
    }, [db]);

    useEffect(() => {
        console.log('messages: ', messages.length);
    }, [messages]);


    const onNameChange = (e) => {
        setName(e.target.value);
    }

    const onTelChange = (e) => {
        setTel(e.target.value);
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();

        const uuid = dayjs().format('YYYY-MM-DD-HH:mm:ss_SSS');

        const newMessageRef = ref(db, `opentech-partners/messages/${uuid}`);
        const form = {
            received: dayjs().format('DD/MM/YYYY HH:mm'),
            name: name,
            tel: tel,
            email: email,
            message: message,
            mailSent: false,
            error: null
        }

        set(newMessageRef, form)
            .then(() => {
                setMailSent(true);
            })
            .catch(error => console.log(error));

        console.log('Form: ', form);

    }

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ backgroundColor: 'rgba(0,0,0,0.3' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '35vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px', fontSize: '4em' }}>Contact</h1>
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row style={{ backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', marginBottom: '30px' }}>
                    <Col sm={{ span: 6, offset: 3 }}>
                        <div>
                            <p>Please complete the form below to get in touch and someone will contact you soon</p>
                        </div>
                    </Col>
                </Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>

                    <h5 style={{ textAlign: 'center', marginBottom: '25px' }}>{mailSent && <span>Thank you. Your message has been sent successfully and someone will be in touch soon</span>}</h5>
                    

                        <Col sm={12} md={6}>
                       
                            <Form>
                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control placeholder="Name" type="text" value={name} onChange={e => onNameChange(e)} />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control placeholder="Email address" type="email" value={email} onChange={e => onEmailChange(e)} />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Contact number</Form.Label>
                                    <Form.Control placeholder="Contact number" type="tel" value={tel} onChange={e => onTelChange(e)} />
                                </Form.Group>

                                <Form.Group sm={9} style={{ marginBottom: '15px' }}>
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows="6" placeholder="Message" value={message} onChange={e => onMessageChange(e)} />
                                </Form.Group>

                                <Button type="submit" style={buttonStyleReverse} onClick={handleFormSubmit}>
                                    Send message
                                </Button>
                            </Form>
                            
                        </Col>
                        <Col sm={12} md={6}>
                            <img src={DeansgateSquare} alt="Buildings at Deansgate Square, Manchester" style={{ width: '100%' }} />
                        </Col>
                    </Row>
                </Col>

                <Footer />

            </Container>
        </div>
    );
}

export default ContactPage;