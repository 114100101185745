import { Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import NavigationWhite from '../components/Navigation/NavigationWhite';
import SearchIcon from '../img/search.svg';
import HomepageBackgroundImage from '../img/homepage-background-optimised.jpg';
import Angles from '../img/angles-optimised.jpg';
import CardOne from '../img/card1.jpg';
import CardTwo from '../img/card2.jpg';
import CardThree from '../img/card3.jpg';
import ArrowDownIcon from '../img/svg/arrow-down.svg';

import { Link } from 'react-router-dom';
import Footer from '../components/Footer/Footer';

const HomePage = () => {

    const homepageBackground = {
        backgroundImage: `url(${HomepageBackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100vh'
    }

    const card = {
        backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)',
        color: '#fff',
        marginBottom: '25px'
    }

    const cardText = {
        padding: '30px'
    }

    const buttonStyle = {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        borderRadius: '0px'
    }

    const scrollDown = () => {
        window.scrollTo({
            top: window.innerHeight,
            left: 0,
            behaviour: 'smooth'
        })
    }

    return (
        <div style={homepageBackground}>
            <Container fluid>
                <Row style={{ height: '100vh' }}>
                    <NavigationWhite />
                    <Col>
                        <div style={{ height: '45vh', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <h1 style={{ color: '#fff', marginBottom: '50px' }}>OPEN<b>TECH</b> PARTNERS</h1>
                                <h2 style={{ color: '#fff', marginBottom: '50px' }}>IT recruitment specialists</h2>

                                <Col sm={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }} lg={{ span: 6, offset: 3 }}>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                                            <Col>
                                                <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                                                    Role
                                                </Form.Label>
                                                <InputGroup sm={9}>
                                                    <InputGroup.Text><img src={SearchIcon} alt="Search icon" style={{ height: '30px' }} /></InputGroup.Text>
                                                    <Form.Control placeholder="Search roles - Coming soon" />
                                                </InputGroup>
                                            </Col>
                                            <Col sm={3} style={{ display: 'flex', alignItems: 'center' }}>
                                                <Button type="submit" style={buttonStyle} disabled>
                                                    Search roles
                                                </Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </Col>
                            </div>
                        </div>
                    </Col>
                    <Button style={{ height: '50px', borderRadius: '25px', left: 'calc(50% - 25px)', right: '50vw', padding: '0px', backgroundColor: 'transparent', border: 'none' }} onClick={scrollDown}><img src={ArrowDownIcon} style={{ height: '50px'}} /></Button>
                </Row>

                <Row style={{ display: 'none', backgroundColor: '#0b8752', backgroundImage: 'linear-gradient(to right, #2ac64e, #0b8752)', color: '#fff', textAlign: 'center', padding: '40px', height: '20vh', marginBottom: '30px' }}>
                    <Col sm={12}>
                        <div>
                            <p>Some of the businesses we partner with include:</p>
                        </div>
                    </Col>
                </Row>

                <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                    <Col sm={{ span: 5, offset: 1 }}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img src={Angles} alt="High rise buildings" style={{ width: '100%' }} />
                        </div>

                    </Col>
                    <Col sm={5}>
                        <div style={{ height: '400px', display: 'flex', alignItems: 'center', alignText: 'center', padding: '50px' }}>
                            <p>OpenTech Partners is a nationwide staffing and recruiting firm, combining almost a decade of experience with trusted and reliable consultancy and advice in the recruitment industry. With highly trained and specialist recruitment professionals, OpenTech Partners are proud to assist and support our respected clients as they scale and develop their IT, Digital and Technology teams with the best in the market.</p>
                        </div>

                    </Col>
                </Row>

                <Col sm={{ span: 10, offset: 1 }}>
                    <Row style={{ paddingTop: '50px', marginBottom: '30px' }}>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardOne} alt="A meeting room" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>View roles</h3>
                                    <p>Coming soon - Search for your next role</p>
                                    <Button style={buttonStyle} disabled>
                                        <Link to="/candidates" style={{ color: '#fff', textDecoration: 'none' }}>View roles</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardTwo} alt="Two women writing on a white board" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Join us</h3>
                                    <p>Join us today and start searching for your new role</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>Join us</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                        <Col sm={4}>
                            <div style={card}>
                                <img src={CardThree} alt="An office block" style={{ width: '100%' }} />
                                <div style={cardText}>
                                    <h3>Clients</h3>
                                    <p>Contact us about your IT recruitment needs</p>
                                    <Button style={buttonStyle}>
                                        <Link to="/contact" style={{ color: '#fff', textDecoration: 'none' }}>How we work</Link>
                                    </Button>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </Col>

                <Footer />

            </Container>
        </div>

    );
}

export default HomePage;